up.compiler('[autofocus]', (element) => {
  // This compiler will not try to autofocus a field itself (browser handles [autofocus] itself),
  // but for autofocused input fields, it places the caret at the end of the line.

  if (element.matches('input')) {
    const length = element.value.length

    try {
      element.setSelectionRange(length, length)
    } catch (error) {
      // setSelectionRange is defined but unsupported for some input types (e.g. email), throwing an error.
    }
  }
})
