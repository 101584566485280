up.compiler('[data-confirm]', (element) => {

  function onClick(event) {
    const message = element.getAttribute('data-confirm')
    if (!confirm(message)) up.event.halt(event)
  }

  element.addEventListener('click', onClick)

})
