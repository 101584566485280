up.compiler('body', (element) => {

  function setLoading(isLoading) {
    element.classList.toggle('content-loading', isLoading)
  }

  return [
    up.on('up:request:late', () => { setLoading(true) }),
    up.on('up:request:recover', () => { setLoading(false) }),
  ]

})
