up.compiler('.filterable-list', (element) => {

  const filters = element.querySelector('.filterable-list--filters')

  element.addEventListener('up:fragment:inserted', function(event) {
    const updatedByFilters = filters.contains(event.origin) // don't scroll when list was updated by "load more" button
    const userScrolledDown = element.offsetTop < window.scrollY // only scroll when user scrolled past the top of the list

    if (updatedByFilters && userScrolledDown) {
      up.reveal(element, {
        revealTop: true,
        revealSnap: 0,
        revealPadding: 95,
      })
    }
  })

})
