// Only prepares data on the element for use by a surrounding [lightbox-gallery]
up.compiler('[lightbox-image]', { priority: 1 }, (element, { width, height, thumbUrl, copyrightText, descriptionText, titleText }) => {

  element.getItemData = () => {
    const { src, currentSrc } = element
    const thumbUrl = (currentSrc.indexOf('data:image/svg+xml') === 0) ? src : currentSrc

    return {
      element,
      copyrightText,
      descriptionText,
      titleText,
      w: width,
      h: height,
      src,
      msrc: thumbUrl, // shown while when lightbox was just opened, before loading higher-resolution file from srcset
      srcset: element.getAttribute('data-srcset'),
    }
  }

})
