up.compiler('[file-input]', (element) => {

  const input = element.querySelector('input[type="file"]')
  const filename = element.querySelector('[file-input--filename]')

  const previewImage = element.querySelector('img[file-input--preview]')

  function onDragOver(event) {
    event.preventDefault()
  }

  function onDrop(event) {
    event.preventDefault()
    input.files = event.dataTransfer.files
    onFilePicked()
  }

  function onFilePicked() {
    updateFilename()
    updatePreview()
  }

  function updateFilename() {
    const filenames = Array.from(input.files).map(file => file.name)
    filename.innerText = filenames.join(', ')
  }

  function updatePreview() {
    if (!previewImage) return

    if (input.files.length === 1) {
      const file = input.files[0]

      readImageAsDataURL(file).then((dataURL) => {
        previewImage.src = dataURL
      })
    } else {
      previewImage.src = '//:0'
    }
  }

  function readImageAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })
      reader.readAsDataURL(file)
    })
  }

  input.addEventListener('change', onFilePicked)
  element.addEventListener('drop', onDrop)
  element.addEventListener('dragover', onDragOver)
  element.classList.add('drop-area')

})
