import environment from '../util/environment'

up.layer.config.modal.dismissable = ['key', 'button']

// Some articles link to files which need to be handled without Unpoly. Note that this can not be `[href^="/system/"]` because link hrefs are sometimes "../../../system/..." or similar.
up.link.config.noFollowSelectors.push('[href*="/system/"]')

up.feedback.config.currentClasses.push('active')

up.form.config.submitSelectors.push('form')

up.motion.config.enabled = !environment.isTest

up.radio.config.hungrySelectors.push(
  'meta[name="csrf-token"]',
  'meta[name="csrf-param"]',
  'head meta[name="description"]',
  'head meta[name="robots"]',
  'head meta[name="googlebot"]',
  'head meta[name="bingbot"]',
  'head meta[property="og:locale"]',
  'head meta[property="og:type"]',
  'head meta[property="og:title"]',
  'head meta[property="og:description"]',
  'head meta[property="og:url"]',
  'head meta[property="og:image"]',
  'head meta[property="og:site_name"]',
  'head meta[property="article:modified_time"]',
)
