up.compiler('[search-form]', (element) => {

  function onSubmit(event) {
    // query element at runtime because [search-form] is placed in the global layout
    // and the query field itself might have been replaced, e.g. through [up-hungry]
    const queryField = element.querySelector('[name*="query"]')

    if (queryField.value === '') {
      queryField.focus()
      up.event.halt(event)
    }
  }

  element.addEventListener('submit', onSubmit)

})
