import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/photoswipe.css'

up.compiler('[lightbox-gallery]', async (element) => {

  const lightbox = new PhotoSwipeLightbox({
    gallery: element,
    children: '[lightbox-image]',
    showHideAnimationType: 'zoom',
    pswpModule: (await import('photoswipe')).default,
  })

  lightbox.on('itemData', (event) => {
    const lightboxImage = event.itemData.element
    event.itemData = lightboxImage.getItemData()
  })

  // Support captions: https://photoswipe.com/caption/
  lightbox.on('uiRegister', () => {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (captionElement, _pswp) => {
        lightbox.pswp.on('change', () => {
          const { copyrightText, descriptionText, titleText } = lightbox.pswp.currSlide.data

          captionElement.innerHTML = ''

          if (titleText) up.element.affix(captionElement, 'h5.m-0', { text: titleText })
          if (descriptionText) up.element.affix(captionElement, 'div', { text: descriptionText })
          if (copyrightText) up.element.affix(captionElement, '.small.text-muted', { text: `Copyright information: ${copyrightText}` })
        })
      },
    })
  })

  lightbox.init()

})
