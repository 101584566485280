up.compiler('[events-page]', (element) => {

  function getPastEventsContainer() {
    return element.querySelector('[events-page--past-events]')
  }

  function isChecked() {
    return getPastEventsContainer().querySelector('input[type="checkbox"]').checked
  }

  let lastChecked = isChecked()

  up.on(element, 'up:fragment:inserted', (_event) => {
    const nowChecked = isChecked()

    if (!lastChecked && nowChecked) {
      up.reveal(getPastEventsContainer(), {
        revealTop: true,
        revealSnap: 0,
        revealPadding: 95,
      })
    }

    lastChecked = nowChecked
  })

})
